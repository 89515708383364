<template>
  <v-container v-show="isPageComplete">
    <StudyLogs v-show="webApp.drawer.list.studyLogs" ref="StudyLogs" />
    <Assignment v-show="webApp.drawer.list.assignment" ref="Assignment" />
    <Search v-show="webApp.drawer.list.search" ref="Search" />
    <QuestionsList v-show="webApp.drawer.list.questions" ref="QuestionsList" />

    <!-- ドロワー -->
    <v-navigation-drawer
      app
      color="grey lighten-4"
      v-model="webApp.drawer.isShow"
    >
      <v-list style="margin-bottom: -10px; padding: 0px">
        <!-- topへ -->
        <v-list-item link @click="gf_moveToTop()">
          <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>TOP</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- 学習記録 -->
        <v-list-item
          link
          @click="gf_switchDrawerList('studyLogs')"
          v-bind:input-value="webApp.drawer.list.studyLogs"
          :color="webApp.drawer.listItemColor"
        >
          <v-list-item-icon><v-icon>mdi-file-chart</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>学習記録</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- 課題 -->
        <v-list-item
          link
          @click="gf_switchDrawerList('assignment')"
          v-bind:input-value="webApp.drawer.list.assignment"
          :color="webApp.drawer.listItemColor"
        >
          <v-list-item-icon><v-icon>mdi-head-alert</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>到達課題</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- 問題検索 -->
        <v-list-item
          link
          @click="gf_switchDrawerList('search')"
          v-bind:input-value="webApp.drawer.list.search"
          :color="webApp.drawer.listItemColor"
        >
          <v-list-item-icon><v-icon>mdi-magnify</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>問題検索</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- 演習問題 -->
        <v-list-item
          link
          @click="gf_switchDrawerList('questions')"
          v-bind:input-value="webApp.drawer.list.questions"
          :color="webApp.drawer.listItemColor"
        >
          <v-list-item-icon><v-icon>mdi-kabaddi</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>問題演習</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import QuestionsList from '../comp/QuestionsList.vue';
import StudyLogs from '../comp/StudyLogs.vue';
import Search from '../comp/Search.vue';
import Assignment from '../comp/Assignment.vue';

export default {
  name: 'kamokuHome',
  components: { Search, QuestionsList, StudyLogs, Assignment },
  data() {
    return {
      isPageComplete: false,
      kamokuName: 'linuc101', //要初期設定
    };
  },
  computed: { ...mapState(['webApp']) },
  async mounted() {
    this.$store.commit('loading', true);

    //状態維持
    await this.gf_keepActive();
    await this.gf_keepLogin();

    //科目ページ共通の処理
    this.gf_set_kamokuPage();

    //科目データの整備
    await this.gf_set_subjectData(this.kamokuName);

    //子コンポーネントの設定
    this.$refs.QuestionsList.init(this.kamokuName);
    this.$refs.Search.setContent(this.kamokuName);

    //ユーザーデータの整備
    if (this.gf_isLogin()) {
      await this.gf_set_userKamokuData(this.kamokuName);
      this.$refs.StudyLogs.setContent(this.kamokuName);
      this.$refs.Assignment.setContent(this.kamokuName);
    }

    this.isPageComplete = true;
    this.$store.commit('loading', false);
  },
};
</script>
